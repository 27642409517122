import React from "react";
import { Helmet } from "react-helmet";
import {LinkWithUTM as Link} from '../../components/LinkWithUTM'
import { StaticImage } from "gatsby-plugin-image";
import { graphql, useStaticQuery } from 'gatsby'
import BackgroundImage from 'gatsby-background-image'

import Header from "../../components/Header";
import Footer from "../../components/Footer";

import "../../../scss/main.scss";

import Trustpilot from "../../components/Trustpilot/Trustpilot";
import CqcApproved from "../../components/CqcApproved";

import {
	ConditionSplash,
	ConditionDescription,
	ConditionDescriptionRightPanel,
	ConditionDescriptionRightPanelParagraph,
	ConditionInfographicSection,
	ConditionInfographicSectionIntro,
	ConditionInfographicMainContent,
	ConditionInfographicDescription,
	ConditionInfographicDescriptionParagraph,
	ConditionsInforgraphicTypes,
	ConditionsInfographicTypesHeading,
	ConditionsInfographicTypesDetail,
	ConditionsInfographicTypesDetailSection,
	ConditionsInfographicSymptons,
	ConditionsInfographicSymptonsHeader,
	ConditionsInfographicSymptonDiagram,
	ConditionsInfographicSymptonDiagramMainCopy,
	ConditionsInfographicSymptonDiagramTopics,
	ConditionsInfographicSymptonDiagramTopic,
	ConditionsInfographicSymptonDiagramTopicHeading,
	ConditionsInfographicSymptonDiagramTopicCopy,
	ConditionStats,
	ConditionStatsMainContent,
	ConditionStat,
	ConditionStatCopy,
	ConditionStatCopyInline,
	ConditionStatCopyEmph,
	ConditionStatCopyEmphInline,
	ConditionLivein,
	ConditionLiveinHeader,
	ConditionLiveinHeaderCopy,
	ConditionLiveinHomeCard,
	ConditionLiveinHomeCardMainCopy,
	ConditionLiveinHomeCardMainTip,
	ConditionLiveinHomeCardMainTipTitle,
	ConditionLiveinHomeCardMainTipCopy,
	ConditionTrainingCard,
	ConditionTrainingCardMainCopy,
	ConditionTrainingCardTip,
	ConditionTrainingCardTipItem,
} from '../../components/Conditions'

export default function Cancer() {
	const dementiaBackgroundFluid = useStaticQuery(graphql`
		query {
			background: file(relativePath: {eq: "dementia-image-02.jpg"}){
				childImageSharp {
					fluid(quality: 90) {
					  ...GatsbyImageSharpFluid_withWebp
					}
				}
			}
		}
	`).background.childImageSharp.fluid
	return (
		<>
			<Helmet>
				<meta charset="UTF-8" />
				<meta http-equiv="X-UA-Compatible" content="IE=edge" />
				
				<meta name="viewport" content="width=device-width, initial-scale=1.0" />
				<title>Cancer care at home</title>
				<meta property="og:title" content="Edyn Care | Cancer care at home"/>
				<meta property="og:description" content="High quality care in the comfort of your own home."/>
				<meta property="og:image" content="https://www.edyn.care/assets/marketing-site/images/condition_cancer_splash.jpeg"/>
				<meta property="og:url" content="https://www.edyn.care/condition/cancer" />

				<meta name="twitter:card" content="summary_large_image" />
				<meta property="twitter:domain" content="edyn.care" />
				<meta property="twitter:url" content="https://www.edyn.care/condition/cancer" />
				<meta name="twitter:title" content="Edyn Care | Cancer" />
				<meta name="twitter:description" content="High quality care in the comfort of your own home." />
				<meta name="twitter:image" content="https://www.edyn.care/assets/marketing-site/images/open-graph-img.png" />
				
			</Helmet>

			<div className="container">
				<Header />
				<main>
					<ConditionSplash>
						<StaticImage 
							src="../../../static/assets/marketing-site/images/circle-dementia.svg" 
							placeholder="blurred"
						/>
						<h3>Cancer care at home</h3>
					</ConditionSplash>
					<ConditionDescription>
						<StaticImage 
							src="../../../static/assets/marketing-site/images/condition_cancer_splash.jpeg"
							placeholder="blurred"
						/>
						<ConditionDescriptionRightPanel>
							<h3>
								Cancer diagnosis can be a shock, even if you already suspected it. 
							</h3>
							<ConditionDescriptionRightPanelParagraph>
								It’s normal to be worried when someone you love gets diagnosed with cancer. The fear of the unknown can be unsettling. Despite this, it’s vital to remain positive. Thanks to ongoing research, live-in care techniques and assistive technology, it is possible to live well with cancer, and remain at home. 
							</ConditionDescriptionRightPanelParagraph>
							<ConditionDescriptionRightPanelParagraph>
								At Edyn we know first hand the power of having familiar surroundings. We hope, therefore, to lighten the burden and help you through the experience by sharing some useful information that will enable you to get a better picture of cancer care.
							</ConditionDescriptionRightPanelParagraph>
						</ConditionDescriptionRightPanel>
					</ConditionDescription>
					<ConditionInfographicSection>
						<ConditionInfographicSectionIntro>
							<h1>What is Cancer?</h1>
							<p className="secondary pro">
								Cancer is the uncontrolled growth of abnormal cells in the body. 
							</p>
						</ConditionInfographicSectionIntro>
						<ConditionInfographicMainContent>
							<ConditionInfographicDescription>
								<h3>
									Cancer is not one disease, but a collection of related diseases that can occur almost anywhere in the body.
								</h3>
								<ConditionInfographicDescriptionParagraph>
									At its most basic, cancer is a disease of the genes in the cells of our body. Genes control the way our cells work. But, changes to these genes can cause cells to malfunction, causing them to grow and divide when they should - or prevent them from dying when they should. Most cancers start due to changes that happen over a person’s lifetime. Sometimes chancers start due to inherited faulty genes passing down in families but this is rare. 
								</ConditionInfographicDescriptionParagraph>
								<ConditionInfographicDescriptionParagraph>
									When your loved one is diagnosed with cancer, the diagnosing clinician should be able to tell your loved one what type they have. This is useful to know since symptoms and patterns of progression vary from one to another, and it may help you and your loved one feel more prepared for what the future holds.
								</ConditionInfographicDescriptionParagraph>
							</ConditionInfographicDescription>
							<ConditionsInforgraphicTypes>
								<ConditionsInfographicTypesHeading>
									There are 5 main cancer groups:
								</ConditionsInfographicTypesHeading>
								<ConditionsInfographicTypesDetail>
									<ConditionsInfographicTypesDetailSection title={`Carcinoma`}>
										<ul>
											<li>
												This cancer begins in the skin or in tissues that line or cover internal organs. There are differ subtypes. 
											</li>
											<li>
												Starts in epithelial tissues. 
											</li>
											<li>
												Carcinomas are the most common type of cancer. They make up 85% of cancer cases in the UK.
											</li>
										</ul>
									</ConditionsInfographicTypesDetailSection>
									<ConditionsInfographicTypesDetailSection title={`Sarcomas`}>
										<ul>
											<li>
												This cancer begins in connective tissues. These are the supporting tissues of the body. Connective tissues include the bones, cartilage, tendons and fibrous tissue that support organs. 
											</li>
											<li>
												Sarcomas are much less common than carcinomas. There are two main types: bone sarcomas and soft tissue sarcoma. 
											</li>
											<li>
												These make up less than 1% of cancer cases. 
											</li>
										</ul>
									</ConditionsInfographicTypesDetailSection>
									<ConditionsInfographicTypesDetailSection title={`Leukaemias`}>
										<ul>
											<li>
												Is cancer of the white blood cells. The bone marrow makes too many white blood cells. The blood cells are not fully formed and so they don’t work properly. 
											</li>
											<li>
												They are uncommon. They make up 3% of the cancer cases in the UK. 
											</li>
										</ul>
									</ConditionsInfographicTypesDetailSection>
									<ConditionsInfographicTypesDetailSection title={`Lymphoma and Myeloma`}>
										<ul>
											<li>
												These cancers begin in the cells of the immune system. 
											</li>
											<li>
												Lymphomas make up about 5% of cancer cases in the UK.
											</li>
											<li>
												Myeloma makes up 1% of cancer cases in the UK.
											</li>
										</ul>
									</ConditionsInfographicTypesDetailSection>
									<ConditionsInfographicTypesDetailSection title={`Brain and spinal cord cancers`}>
										<ul>
											<li>
												These cancers begin in the cells of the brain or spinal cord. 
											</li>
											<li>
												The brain controls the body by sending messages along nerve fibres. The fibres run out of the brian and join together to make the spinal cord, which also takes messages from the body to the brain. 
											</li>
											<li>
												These cancers make up about 3% of cancers in the UK. 
											</li>
										</ul>
									</ConditionsInfographicTypesDetailSection>
								</ConditionsInfographicTypesDetail>
							</ConditionsInforgraphicTypes>

							<ConditionsInfographicSymptons>
								<ConditionsInfographicSymptonsHeader>
									There are over 200 different types of cancer that can cause many different symptoms. 
								</ConditionsInfographicSymptonsHeader>
								<ConditionsInfographicSymptonDiagram>
									<ConditionsInfographicSymptonDiagramMainCopy>
										Sometimes symptoms are linked to certain cancer types. But signs can also be more general. Remember, anyone can develop cancer but it’s more common as we get older.
									</ConditionsInfographicSymptonDiagramMainCopy>
									<ConditionsInfographicSymptonDiagramTopics>
										<ConditionsInfographicSymptonDiagramTopic>
											<ConditionsInfographicSymptonDiagramTopicHeading>Weight loss:</ConditionsInfographicSymptonDiagramTopicHeading>
											<ConditionsInfographicSymptonDiagramTopicCopy>
												Small weight changes over time are quite normal, but if you lose a noticeable amount of weight without trying to, tell your doctor.
											</ConditionsInfographicSymptonDiagramTopicCopy>
										</ConditionsInfographicSymptonDiagramTopic>
										<ConditionsInfographicSymptonDiagramTopic>
											<ConditionsInfographicSymptonDiagramTopicHeading>Fatigue:</ConditionsInfographicSymptonDiagramTopicHeading>
											<ConditionsInfographicSymptonDiagramTopicCopy>
												There are lots of reasons you may feel more tired than usual, particularly if you’re going through a stressful event, or having trouble sleeping. But if you’re tired for no clear reason, it could be a sign that something is wrong. 
											</ConditionsInfographicSymptonDiagramTopicCopy>
										</ConditionsInfographicSymptonDiagramTopic>
										<ConditionsInfographicSymptonDiagramTopic>
											<ConditionsInfographicSymptonDiagramTopicHeading>
												Unusual lump or swelling anywhere: 
											</ConditionsInfographicSymptonDiagramTopicHeading>
											<ConditionsInfographicSymptonDiagramTopicCopy>
												Persistent lumps of swelling in any part of your body should be taken seriously. That includes any lumps on the neck, armpit, stomach, groin, chest, breast or testicle. 
											</ConditionsInfographicSymptonDiagramTopicCopy>
										</ConditionsInfographicSymptonDiagramTopic>
										<ConditionsInfographicSymptonDiagramTopic>
											<ConditionsInfographicSymptonDiagramTopicHeading>
												Very heavy night sweats: 
											</ConditionsInfographicSymptonDiagramTopicHeading>
											<ConditionsInfographicSymptonDiagramTopicCopy>
												Sweating at night can be caused by infections or can be a side effect of certain medications. It is also often experienced by women around the time of the menopause. But very heavy, drenching night sweats can also be a sign of cancer. 
											</ConditionsInfographicSymptonDiagramTopicCopy>
										</ConditionsInfographicSymptonDiagramTopic>
										<ConditionsInfographicSymptonDiagramTopic>
											<ConditionsInfographicSymptonDiagramTopicHeading>
												Unexplained pain or ache: 
											</ConditionsInfographicSymptonDiagramTopicHeading>
											<ConditionsInfographicSymptonDiagramTopicCopy>
												Pain is one way our bodies tell us that something is wrong. As we get older, it’s more common to experience aches and pains. But unexplained pain could be a sign of something more serious. 
											</ConditionsInfographicSymptonDiagramTopicCopy>
										</ConditionsInfographicSymptonDiagramTopic>
									</ConditionsInfographicSymptonDiagramTopics>
								</ConditionsInfographicSymptonDiagram>
							</ConditionsInfographicSymptons>
						</ConditionInfographicMainContent>
					</ConditionInfographicSection>
					<ConditionStats>
						<BackgroundImage className="f-c-image" fluid={dementiaBackgroundFluid}>
							<h1>Cancer facts and stats</h1>
						</BackgroundImage>
						<ConditionStatsMainContent>
							<ConditionStat>
								<ConditionStatCopyEmph>2.5 million</ConditionStatCopyEmph>
								<ConditionStatCopy>
									people living with cancer in the UK
								</ConditionStatCopy>
								{/*   <hr>  */}
							</ConditionStat>
							<ConditionStat>
								<ConditionStatCopyInline>Cancer survival is improving and has</ConditionStatCopyInline>
								<ConditionStatCopyEmphInline>doubled</ConditionStatCopyEmphInline>
								<ConditionStatCopy>
									in the last 40 years in the UK
								</ConditionStatCopy>
								{/*   <hr>  */}
							</ConditionStat>
							<ConditionStat>
								<ConditionStatCopy>
								The number of people living with cancer in the UK is increasing 
								</ConditionStatCopy>
								<ConditionStatCopyEmph>3% every year</ConditionStatCopyEmph>
								{/*   <hr>  */}
							</ConditionStat>
							<ConditionStat>
								<ConditionStatCopyEmph>38%</ConditionStatCopyEmph>
								<ConditionStatCopy>of cancers are preventable</ConditionStatCopy>
								{/*   <hr>  */}
							</ConditionStat>
							<ConditionStat>
								<ConditionStatCopyEmph>Every two minutes</ConditionStatCopyEmph>
								<ConditionStatCopy>
									someone in the UK is diagnosed with cancer
								</ConditionStatCopy>
								{/*   <hr>  */}
							</ConditionStat>
							<ConditionStat>
								<ConditionStatCopyInline>Breast, prostate, lung and bowel cancers together accounted for</ConditionStatCopyInline>
								<ConditionStatCopyEmphInline>53%&nbsp;</ConditionStatCopyEmphInline>
								<ConditionStatCopyInline>
									of all new cancer cases in the UK in 2017
								</ConditionStatCopyInline>
								{/*   <hr>  */}
							</ConditionStat>

							{/* <div className="stats">
								<p className="primary">A person is diagnosed with dementia every</p>
								<h3 >three minutes</h3>
							</div> */}
						</ConditionStatsMainContent>
					</ConditionStats>
					<ConditionLivein>
						<ConditionLiveinHeader>
							<h1>Cancer live-in care </h1>
							<ConditionLiveinHeaderCopy>
								We understand that with patience, trust and genuine care, we
								can help those diagnosed with cancer maintain a high quality
								of life and live comfortably in their own home.
							</ConditionLiveinHeaderCopy>
						</ConditionLiveinHeader>
						<ConditionLiveinHomeCard title={`Ensuring your home is cancer friendly`}>
							<ConditionLiveinHomeCardMainCopy>
								When considering live-in care it’s important to ensure that
								the home of the care recipient is safe and secure. Does it
								compensate for any difficulties they are experiencing? Does
								it keep them safe? Does it support their mobility? <br />
								<br />
								At Edyn as part of our approach we complete a home risk
								assessment for all of our clients. Our care managers ensure
								that your home is the best possible setting for care. Small
								changes can make big differences.
							</ConditionLiveinHomeCardMainCopy>
							<ConditionLiveinHomeCardMainTip>
								<ConditionLiveinHomeCardMainTipTitle>Top cancer care tip:</ConditionLiveinHomeCardMainTipTitle>
								<br />
								<ConditionLiveinHomeCardMainTipCopy>
									Ensuring mobility areas are safe with adequate space and having a dedicated area for medication with a well defined medication schedule are both crucial.
								</ConditionLiveinHomeCardMainTipCopy>
							</ConditionLiveinHomeCardMainTip>
						</ConditionLiveinHomeCard>
						<ConditionTrainingCard title={`Professional carers trained specifically for cancer:`}>
							<ConditionTrainingCardMainCopy>
								Our professional care teams receive on-going training,
								mentoring and support based on the latest thinking and
								research that ensure they have the skills to deliver
								positive cancer care. This includes:
							</ConditionTrainingCardMainCopy>
							<ConditionTrainingCardTip>
								<ConditionTrainingCardTipItem>Managing and administering medications</ConditionTrainingCardTipItem>
								<ConditionTrainingCardTipItem>Providing emotional support</ConditionTrainingCardTipItem>
								<ConditionTrainingCardTipItem>Support post chemotherapy, radiotherapy or hormonal therapy</ConditionTrainingCardTipItem>
								<ConditionTrainingCardTipItem>Expert palliative care</ConditionTrainingCardTipItem>
								<ConditionTrainingCardTipItem>Support with bowel, stoma and catheter care</ConditionTrainingCardTipItem>
								<ConditionTrainingCardTipItem>Nutrition and relaxation</ConditionTrainingCardTipItem>
								<ConditionTrainingCardTipItem>Running errands, such as collecting prescriptions and shopping for food</ConditionTrainingCardTipItem>
								<ConditionTrainingCardTipItem>Managing your hospital visits</ConditionTrainingCardTipItem>
							</ConditionTrainingCardTip>
						</ConditionTrainingCard>
					</ConditionLivein>
					{/* <section>
						<div className="customer-say">
							<div className="customer-words">
								<p className="secondary">What our customers say:</p>
								<div className="customer-words-group">
									<h3 className="text-with-quotes">
										<span className="customer-words-group__quotes">“</span>
										What makes Edyn stand out from other agencies we have used
										for live-in care is the fact that they care - the human
										touch is there.
										<span className="customer-words-group__quotes">”</span>
									</h3>
								</div>
							</div>
							<div className="customer-images">
								<div className="customer-images-bubbles">
									<StaticImage placeholder="blurred" src="../../../static/assets/marketing-site/images/dementia-quote-customers1.png" />
									<StaticImage placeholder="blurred" src="../../../static/assets/marketing-site/images/dementia-quote-customers2.png" />
								</div>
								<div className="customer-names">
									<p className="secondary">
										Sarah M.
										<br />
										<span>Mum</span>
									</p>
									<p className="secondary">
										Julie M.
										<br />
										<span>Daughter</span>
									</p>
								</div>
							</div>
						</div>
					</section> */}

					<section className="live-in dementia">
						<h1 className="live-in-heading">
							Why choose live-in care with Edyn
						</h1>
						<div className="reasons">
							<div className="reason">
								<StaticImage placeholder="blurred"
									className="img-live-in"
									src="../../../static/assets/marketing-site/images/proffesional-carer.png"
									alt="proffesional carers"
								/>
								<h3>First class professional carers</h3>
								<p className="primary">
									Each one of our carers is trained in house by our care support
									team.
								</p>
							</div>
							<div className="reason">
								<StaticImage placeholder="blurred"
									className="img-live-in"
									src="../../../static/assets/marketing-site/images/support-teams.png"
									alt="Support Teams"
								/>
								<h3>Support teams to manage your care.</h3>
								<p className="primary">
									Our dedicated care specialists will handle the progression of
									your care.
								</p>
							</div>
							<div className="reason">
								<StaticImage placeholder="blurred"
									className="img-live-in"
									src="../../../static/assets/marketing-site/images/technology.png"
									alt="Technology"
								/>
								<h3>Technology to keep everyone updated</h3>
								<p className="primary">
									Stay informed and up to date with our smart online portal.
								</p>
							</div>
						</div>
						<Link to="/approach/" className="button-link">
							<button className="btn-primary">
								Learn more about our process
							</button>
						</Link>
					</section>
					{/* <Trustpilot /> */}
					<CqcApproved />
				</main>
				<Footer />
			</div>
		</>
	);
}
